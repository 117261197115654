import { createIcon } from "../create-icon"

export const SearchIcon = createIcon({
  title: "SearchIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40624 9.54167C8.77901 10.1357 7.93205 10.5 7 10.5C5.067 10.5 3.5 8.933 3.5 7C3.5 5.067 5.067 3.5 7 3.5C8.933 3.5 10.5 5.067 10.5 7C10.5 7.93205 10.1357 8.77901 9.54167 9.40624C9.51667 9.42558 9.49261 9.44673 9.46967 9.46967C9.44673 9.49261 9.42558 9.51667 9.40624 9.54167ZM9.96544 11.0261C9.13578 11.6382 8.11014 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.11014 11.6382 9.13578 11.0261 9.96544L13.7803 12.7197C14.0732 13.0126 14.0732 13.4874 13.7803 13.7803C13.4874 14.0732 13.0126 14.0732 12.7197 13.7803L9.96544 11.0261Z"
        fill="currentColor"
      />
    </>
  ),
})
