import { createIcon } from "../create-icon"

export const Result500Icon = createIcon({
  title: "Result500Icon",
  viewBox: "0 0 97 97",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.61 54.523a6.234 6.234 0 0 1 3.356.912 6.484 6.484 0 0 1 2.393 2.584 5.054 5.054 0 0 1-.115 4.865 6.844 6.844 0 0 1-2.53 2.536c-1.046.599-2.23.9-3.428.873H66.035a7.538 7.538 0 0 0-3.663.874 7.833 7.833 0 0 0-2.838 2.56l-.009.013a5.06 5.06 0 0 0-.94 2.7 5.089 5.089 0 0 0 .665 2.787 7.592 7.592 0 0 0 2.81 2.775 7.279 7.279 0 0 0 3.773.942h18.06a.5.5 0 1 1 0 1h-18.04a8.279 8.279 0 0 1-4.284-1.07 8.594 8.594 0 0 1-3.183-3.143l-.002-.004a6.088 6.088 0 0 1-.798-3.336 6.06 6.06 0 0 1 1.123-3.229 8.833 8.833 0 0 1 3.196-2.879 8.538 8.538 0 0 1 4.144-.99h10.254a5.593 5.593 0 0 0 2.915-.741 5.844 5.844 0 0 0 2.16-2.168v-.002a4.055 4.055 0 0 0 .093-3.903v-.003a5.484 5.484 0 0 0-2.024-2.187 5.233 5.233 0 0 0-2.823-.766h-20.91a.5.5 0 1 1 0-1H76.61z"
        fill="#D4D6DC"
      />
      <path
        d="M56.215 63.26c1.458.735 1.458 1.993 0 2.832L35.49 77.21a6.427 6.427 0 0 1-5.207 0L9.457 66.092c-1.458-.734-1.458-1.992 0-2.831L30.18 52.143a6.429 6.429 0 0 1 5.207 0L56.215 63.26z"
        fill="#D3D5DA"
      />
      <path
        d="m34.319 34.484 22.053 12.818c-.252-.116-.56-.09-.836-.062-.276.028-.54.128-.765.292L32.746 34.694a1.567 1.567 0 0 1 1.573-.21z"
        fill="url(.7oeg1rxr2a)"
      />
      <path
        d="M32.787 69.9 10.762 57.01a1.592 1.592 0 0 1-.646-1.447l22.025 12.89a1.603 1.603 0 0 0 .646 1.447z"
        fill="#4672ED"
      />
      <path
        d="m32.128 68.462-22.025-12.89-.021-5.087 22.025 12.89.021 5.087z"
        fill="url(.tvol8y2ytb)"
      />
      <path
        d="m34.292 59.546-22.035-12.9 20.578-11.958 22.025 12.89-20.568 11.968z"
        fill="#5880ED"
      />
      <path
        d="M32.118 63.424 10.082 50.492c.04-.774.262-1.528.645-2.2a4.925 4.925 0 0 1 1.563-1.67l21.922 12.854c-.65.428-1.078 1.042-1.462 1.723-.384.68-.6 1.443-.632 2.225z"
        fill="url(.u67wlkag1c)"
      />
      <path
        d="m34.407 20.656 22.026 12.932a1.564 1.564 0 0 0-1.573.158l-22.025-12.89a1.566 1.566 0 0 1 1.572-.2z"
        fill="url(.e2fcjdj7ad)"
      />
      <path
        d="m32.741 56.064-22.025-12.89a1.593 1.593 0 0 1-.646-1.447l22.026 12.89a1.603 1.603 0 0 0 .645 1.447z"
        fill="#4672ED"
      />
      <path
        d="m32.741 56.064-22.025-12.89a1.593 1.593 0 0 1-.646-1.447l22.026 12.89a1.603 1.603 0 0 0 .645 1.447z"
        fill="#4771E7"
      />
      <path
        d="m32.096 54.627-22.025-12.9-.021-5.087 22.025 12.89.02 5.097z"
        fill="url(.bdr44hq4je)"
      />
      <path
        d="m34.292 45.67-22.035-12.9 20.578-11.958 22.025 12.89L34.292 45.67z"
        fill="#5880ED"
      />
      <path
        d="M54.828 33.703c1.23-.713 2.219-.136 2.219 1.29v5.087a4.923 4.923 0 0 1-.643 2.197c-.384.671-.92 1.241-1.565 1.663L34.26 55.907c-1.218.713-2.218.137-2.218-1.29V49.53a4.892 4.892 0 0 1 .64-2.199 4.852 4.852 0 0 1 1.568-1.66l20.577-11.968z"
        fill="url(.jj7mnirmtf)"
      />
      <path
        d="M32.086 49.53 10.05 36.64c.04-.775.261-1.528.645-2.2a4.927 4.927 0 0 1 1.563-1.67l22.035 12.9c-.646.42-1.184.99-1.568 1.66a4.893 4.893 0 0 0-.64 2.2z"
        fill="url(.11nztx9apg)"
      />
      <path
        d="m37.193 48.914.24.137a.434.434 0 0 0-.23-.066.56.56 0 0 0-.263.066l-.26-.11a.563.563 0 0 1 .26-.078.51.51 0 0 1 .253.051z"
        fill="#fff"
      />
      <path
        d="m36.48 50.619-.239-.136a.471.471 0 0 1-.188-.42 1.465 1.465 0 0 1 .657-1.143l.24.136a1.453 1.453 0 0 0-.657 1.144.465.465 0 0 0 .187.419z"
        fill="#fff"
      />
      <path
        d="M36.928 49.058c.354-.21.645 0 .645.377a1.475 1.475 0 0 1-.645 1.144c-.365.21-.656 0-.656-.378a1.467 1.467 0 0 1 .656-1.143zM43.577 45.328a.1.1 0 0 1 .15.086v.86a.1.1 0 0 1-.05.086l-.591.345a.1.1 0 0 1-.15-.087v-.859a.1.1 0 0 1 .05-.086l.59-.345zM44.837 44.595a.1.1 0 0 1 .15.086v.858a.1.1 0 0 1-.049.087l-.737.429a.1.1 0 0 1-.15-.087v-.85a.1.1 0 0 1 .049-.085l.737-.438z"
        fill="#F0F0F0"
      />
      <path
        d="M51.205 39.264v3.487l-.683.394v-3.488l.683-.393zM52.797 38.362v3.488l-.684.393v-3.487l.684-.394zM54.382 37.45v3.487l-.676.393v-3.487l.676-.394z"
        fill="#BCCBFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.684 52.26a6.342 6.342 0 0 1 3.352.888 6.398 6.398 0 0 1 2.395 2.524 4.857 4.857 0 0 1-.116 4.769 6.77 6.77 0 0 1-2.53 2.476 6.714 6.714 0 0 1-3.425.852H64.11a7.7 7.7 0 0 0-3.669.854 7.768 7.768 0 0 0-2.836 2.496l-.01.013a4.842 4.842 0 0 0-.274 5.331 7.5 7.5 0 0 0 2.809 2.707 7.432 7.432 0 0 0 3.778.92h22.216a.5.5 0 0 1 0 1H63.927a8.43 8.43 0 0 1-4.28-1.044 8.501 8.501 0 0 1-3.184-3.07l-.002-.003a5.848 5.848 0 0 1 .325-6.428 8.765 8.765 0 0 1 3.198-2.811 8.699 8.699 0 0 1 4.14-.965h10.254a5.713 5.713 0 0 0 2.919-.725 5.769 5.769 0 0 0 2.157-2.112l.002-.003a3.854 3.854 0 0 0 .092-3.788l-.001-.002a5.398 5.398 0 0 0-2.021-2.131 5.341 5.341 0 0 0-2.828-.748H57.086a.5.5 0 1 1 0-1h17.598z"
        fill="#000"
      />
      <path
        d="M54.828 47.475c1.23-.714 2.219-.137 2.219 1.29v5.087a4.923 4.923 0 0 1-.643 2.197c-.384.67-.92 1.24-1.565 1.662L34.26 69.68c-1.218.713-2.218.136-2.218-1.29v-5.087c.038-.775.258-1.53.642-2.203a4.894 4.894 0 0 1 1.566-1.667l20.577-11.957z"
        fill="url(.htoprez2oh)"
      />
      <path
        d="m37.193 62.533.24.137a.434.434 0 0 0-.23-.066.56.56 0 0 0-.263.066l-.26-.11a.563.563 0 0 1 .26-.078.51.51 0 0 1 .253.051z"
        fill="#fff"
      />
      <path
        d="m36.48 64.24-.239-.137a.471.471 0 0 1-.188-.42 1.465 1.465 0 0 1 .657-1.143l.24.136a1.453 1.453 0 0 0-.657 1.144.464.464 0 0 0 .187.42z"
        fill="#fff"
      />
      <path
        d="M36.928 62.677c.354-.21.645 0 .645.377a1.476 1.476 0 0 1-.645 1.144c-.365.21-.656 0-.656-.378a1.466 1.466 0 0 1 .656-1.143z"
        fill="#F0F0F0"
      />
      <path
        d="M51.208 53.405v3.488l-.684.393V53.8l.684-.394zM52.8 52.504v3.487l-.684.394v-3.488l.684-.393zM54.385 51.592v3.487l-.676.394v-3.488l.676-.393z"
        fill="#BCCBFF"
      />
      <path
        d="M44.66 58.722a.1.1 0 0 1 .15.087v.86a.1.1 0 0 1-.049.086l-.591.344a.1.1 0 0 1-.15-.086v-.86a.1.1 0 0 1 .05-.086l.59-.345zM43.609 59.34a.1.1 0 0 1 .15.087v.86a.1.1 0 0 1-.05.086l-3.496 2.024a.1.1 0 0 1-.15-.087v-.85a.1.1 0 0 1 .05-.086l3.496-2.034zM45.92 57.998a.1.1 0 0 1 .15.086v.85a.1.1 0 0 1-.048.086l-.737.437a.1.1 0 0 1-.151-.086v-.858a.1.1 0 0 1 .05-.086l.737-.43z"
        fill="#F0F0F0"
      />
      <path
        d="M67.466 56.58c.15-.433.43-.808.801-1.073a2.183 2.183 0 0 1 2.539 0c.37.265.65.64.8 1.073l5.107 8.801c1.145 1.962.245 3.56-2.03 3.56h-10.31c-2.258 0-3.158-1.648-2.029-3.56l5.122-8.801z"
        fill="#fff"
      />
      <path
        d="M70.429 59.48v-.098a.864.864 0 0 0-.255-.612.85.85 0 0 0-.613-.245.848.848 0 0 0-.602.25.86.86 0 0 0-.249.607l.18 4.137c0 .179.07.35.197.477a.668.668 0 0 0 1.145-.477l.197-4.038zm-2.963-2.9c.15-.433.43-.808.801-1.073a2.183 2.183 0 0 1 2.539 0c.37.265.65.64.8 1.073l5.107 8.801c1.145 1.962.245 3.56-2.03 3.56h-10.31c-2.258 0-3.158-1.648-2.029-3.56l5.122-8.801zm2.095 10.416c.23 0 .45-.092.613-.255a.877.877 0 0 0 .188-.95.86.86 0 0 0-.47-.465.845.845 0 0 0-.33-.06.848.848 0 0 0-.602.25.86.86 0 0 0-.25.607.862.862 0 0 0 .522.807.844.844 0 0 0 .33.066z"
        fill="#FF4747"
      />
      <path
        d="M33.25 44.076v.2a.296.296 0 0 1-.176.251.942.942 0 0 1-.854 0 .292.292 0 0 1-.177-.251v-.231a.305.305 0 0 0 .177.252.942.942 0 0 0 .854 0 .292.292 0 0 0 .177-.22z"
        fill="#EFEFEF"
      />
      <path
        d="M33.252 44.068c0-.197-.27-.358-.603-.359-.334 0-.605.158-.605.355 0 .197.27.358.603.359.333 0 .604-.158.605-.355z"
        fill="#fff"
      />
      <path
        d="M53.38 33.305v.2a.296.296 0 0 1-.177.25.942.942 0 0 1-.854 0 .292.292 0 0 1-.177-.25v-.232a.305.305 0 0 0 .177.252.94.94 0 0 0 .854 0 .291.291 0 0 0 .177-.22z"
        fill="#EFEFEF"
      />
      <path
        d="M53.38 33.296c.001-.197-.269-.357-.602-.358-.334-.001-.605.158-.605.355 0 .197.27.357.603.358.333.001.604-.158.605-.355z"
        fill="#fff"
      />
      <path
        d="M14.987 33.305v.2a.295.295 0 0 1-.177.25.942.942 0 0 1-.854 0 .291.291 0 0 1-.177-.25v-.232a.305.305 0 0 0 .177.252.94.94 0 0 0 .854 0 .29.29 0 0 0 .177-.22z"
        fill="#EFEFEF"
      />
      <path
        d="M14.988 33.296c0-.197-.27-.357-.603-.358-.333-.001-.604.158-.605.355 0 .197.27.357.603.358.334.001.605-.158.605-.355z"
        fill="#fff"
      />
      <path
        d="M34.292 22.003v.2a.296.296 0 0 1-.177.251.942.942 0 0 1-.854 0 .292.292 0 0 1-.177-.252v-.23a.305.305 0 0 0 .177.251.942.942 0 0 0 .854 0 .293.293 0 0 0 .177-.22z"
        fill="#EFEFEF"
      />
      <path
        d="M34.293 21.994c0-.197-.27-.357-.603-.358-.334 0-.604.158-.605.355 0 .197.27.357.603.358.333.001.604-.158.605-.355z"
        fill="#fff"
      />
      <path
        d="M42.525 45.946a.1.1 0 0 1 .15.086v.85a.1.1 0 0 1-.05.086l-3.487 2.034a.1.1 0 0 1-.15-.085l-.009-.85a.1.1 0 0 1 .05-.088l3.496-2.033z"
        fill="#F0F0F0"
      />
      <defs>
        <linearGradient
          className="7oeg1rxr2a"
          x1="51.561"
          y1="46.729"
          x2="56.392"
          y2="43.671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5880ED" />
          <stop offset="1" stopColor="#4672ED" />
        </linearGradient>
        <linearGradient
          className="tvol8y2ytb"
          x1="10.082"
          y1="59.474"
          x2="32.128"
          y2="59.474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3462EC" />
          <stop offset="1" stopColor="#4672ED" />
        </linearGradient>
        <linearGradient
          className="u67wlkag1c"
          x1="23.925"
          y1="53.9"
          x2="21.21"
          y2="57.921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5880ED" />
          <stop offset="1" stopColor="#3B68ED" />
        </linearGradient>
        <linearGradient
          className="e2fcjdj7ad"
          x1="44.634"
          y1="30.578"
          x2="49.978"
          y2="22.641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5880ED" />
          <stop offset="1" stopColor="#4672ED" />
        </linearGradient>
        <linearGradient
          className="bdr44hq4je"
          x1="16.709"
          y1="43.834"
          x2="23.668"
          y2="51.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F6BED" />
          <stop offset="1" stopColor="#4672ED" />
        </linearGradient>
        <linearGradient
          className="jj7mnirmtf"
          x1="32.043"
          y1="47.535"
          x2="58.371"
          y2="37.12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#386DE9" />
          <stop offset="1" stopColor="#436EE7" />
        </linearGradient>
        <linearGradient
          className="11nztx9apg"
          x1="25.042"
          y1="39.307"
          x2="21.721"
          y2="44.505"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".433" stopColor="#5880ED" />
          <stop offset=".899" stopColor="#3C6AED" />
        </linearGradient>
        <linearGradient
          className="htoprez2oh"
          x1="57.046"
          y1="53.834"
          x2="36.031"
          y2="66.373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#426EE7" />
          <stop offset="1" stopColor="#366CE9" />
        </linearGradient>
      </defs>
    </>
  ),
})

Result500Icon.displayName = "Result500Icon"
