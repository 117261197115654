import { createIcon } from "../create-icon"

export const SortIcon = createIcon({
  title: "SortIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83854 3H2.75C2.33579 3 2 3.33579 2 3.75C2 4.16421 2.33579 4.5 2.75 4.5H9.83854C9.63094 4.31676 9.5 4.04867 9.5 3.75C9.5 3.45133 9.63094 3.18324 9.83854 3ZM11.1615 4.5C11.3691 4.31676 11.5 4.04867 11.5 3.75C11.5 3.45133 11.3691 3.18324 11.1615 3H13.25C13.6642 3 14 3.33579 14 3.75C14 4.16421 13.6642 4.5 13.25 4.5H11.1615Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83854 7.25H2.75C2.33579 7.25 2 7.58579 2 8C2 8.41421 2.33579 8.75 2.75 8.75H4.83854C4.63094 8.56676 4.5 8.29867 4.5 8C4.5 7.70133 4.63094 7.43324 4.83854 7.25ZM6.16146 8.75C6.36906 8.56676 6.5 8.29867 6.5 8C6.5 7.70133 6.36906 7.43324 6.16146 7.25H13.25C13.6642 7.25 14 7.58579 14 8C14 8.41421 13.6642 8.75 13.25 8.75H6.16146Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83854 11.5H2.75C2.33579 11.5 2 11.8358 2 12.25C2 12.6642 2.33579 13 2.75 13H9.83854C9.63094 12.8168 9.5 12.5487 9.5 12.25C9.5 11.9513 9.63094 11.6832 9.83854 11.5ZM11.1615 13C11.3691 12.8168 11.5 12.5487 11.5 12.25C11.5 11.9513 11.3691 11.6832 11.1615 11.5H13.25C13.6642 11.5 14 11.8358 14 12.25C14 12.6642 13.6642 13 13.25 13H11.1615Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 2.75C9.94772 2.75 9.5 3.19772 9.5 3.75C9.5 4.30228 9.94772 4.75 10.5 4.75C11.0523 4.75 11.5 4.30228 11.5 3.75C11.5 3.19772 11.0523 2.75 10.5 2.75ZM8.5 3.75C8.5 2.64543 9.39543 1.75 10.5 1.75C11.6046 1.75 12.5 2.64543 12.5 3.75C12.5 4.85457 11.6046 5.75 10.5 5.75C9.39543 5.75 8.5 4.85457 8.5 3.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 10.25C9.39543 10.25 8.5 11.1454 8.5 12.25C8.5 13.3546 9.39543 14.25 10.5 14.25C11.6046 14.25 12.5 13.3546 12.5 12.25C12.5 11.1454 11.6046 10.25 10.5 10.25ZM9.5 12.25C9.5 11.6977 9.94772 11.25 10.5 11.25C11.0523 11.25 11.5 11.6977 11.5 12.25C11.5 12.8023 11.0523 13.25 10.5 13.25C9.94772 13.25 9.5 12.8023 9.5 12.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7C4.94772 7 4.5 7.44772 4.5 8C4.5 8.55228 4.94772 9 5.5 9C6.05228 9 6.5 8.55228 6.5 8C6.5 7.44772 6.05228 7 5.5 7ZM3.5 8C3.5 6.89543 4.39543 6 5.5 6C6.60457 6 7.5 6.89543 7.5 8C7.5 9.10457 6.60457 10 5.5 10C4.39543 10 3.5 9.10457 3.5 8Z"
        fill="currentColor"
      />
    </>
  ),
})

SortIcon.displayName = "SortIcon"
