import { createIcon } from "../create-icon"

export const CommentIcon = createIcon({
  title: "CommentIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.01899 3.41196C5.05616 2.43406 6.45572 1.89038 7.90817 1.89038C9.36062 1.89038 10.7602 2.43406 11.7973 3.41196C12.8357 4.391 13.4262 5.72665 13.4262 7.12736L13.4262 7.13002C13.4253 7.32236 13.4133 7.51448 13.3902 7.70552C13.3733 7.85814 13.3464 8.0023 13.3228 8.12595L13.3227 8.12663L13.3143 8.17218L13.313 8.1786L13.313 8.1786C13.0008 9.72933 11.9499 11.0048 10.9083 11.9384C9.85684 12.8809 8.74536 13.5366 8.19564 13.8367C8.00973 13.9382 7.7841 13.9341 7.60197 13.826C7.41983 13.718 7.30817 13.5218 7.30817 13.3101V12.3333C6.07476 12.2053 4.91243 11.6851 4.01899 10.8427C2.98062 9.86372 2.39014 8.52806 2.39014 7.12736C2.39014 5.72665 2.98062 4.391 4.01899 3.41196ZM7.90817 3.09038C6.75194 3.09038 5.64965 3.52377 4.8422 4.28507C4.03595 5.04525 3.59014 6.06846 3.59014 7.12736C3.59014 8.18625 4.03595 9.20947 4.8422 9.96964C5.64965 10.7309 6.75194 11.1643 7.90817 11.1643C8.23954 11.1643 8.50817 11.433 8.50817 11.7643V12.2547C8.99063 11.9434 9.56013 11.5353 10.1074 11.0448C11.0708 10.1813 11.8975 9.12253 12.1358 7.94589L12.1427 7.90822L12.144 7.90115L12.144 7.90116C12.1678 7.7767 12.1866 7.67296 12.1977 7.57127L12.1985 7.56405L12.1986 7.56405C12.2163 7.41858 12.2255 7.27235 12.2262 7.12598C12.2258 6.06757 11.78 5.04492 10.9741 4.28507C10.1667 3.52377 9.0644 3.09038 7.90817 3.09038ZM5.94095 6.0082C5.94095 5.73658 6.16114 5.51639 6.43275 5.51639H9.44915C9.72076 5.51639 9.94095 5.73658 9.94095 6.0082C9.94095 6.27981 9.72076 6.5 9.44915 6.5H6.43275C6.16114 6.5 5.94095 6.27981 5.94095 6.0082ZM6.43275 7.51639C6.16114 7.51639 5.94095 7.73658 5.94095 8.0082C5.94095 8.27981 6.16114 8.5 6.43275 8.5H9.44915C9.72076 8.5 9.94095 8.27981 9.94095 8.0082C9.94095 7.73658 9.72076 7.51639 9.44915 7.51639H6.43275Z"
      fill="currentColor"
    />
  ),
})

CommentIcon.displayName = "CommentIcon"
