import { Locale } from "../interface"

export const enUS = {
  typography: {
    copiedToolTip: "Copied",
    copyToolTip: "Copy",
    expandLabel: "Expand",
  },
  timePicker: {
    ok: "OK",
    placeholder: "Select time",
    placeholders: ["Start time", "End time"],
    now: "Now",
  },
  popover: {
    close: "Close",
  },
  empty: {
    noData: "No data",
  },
  pagination: {
    total: "Total {0}",
    page: "Page",
    go: "Go to ",
  },
  upload: {
    start: "Start",
    cancel: "Cancel",
    upload: "Upload",
    delete: "Delete",
    preview: "Preview",
    retry: "Click to retry",
    dragTip: "Drag and drop the file",
    selectFile: "Select File",
    beginUpload: "Upload File",
    error: "Failed",
  },
  popConfirm: {
    confirm: "Confirm",
    cancel: "Cancel",
  },
  calendar: {
    year: "Year",
    month: "Month",
    day: "Day",
    today: "today",
    week: "Week",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
    startDate: "StartDate",
    endDate: "EndDate",
  },
  datePicker: {
    year: "Year",
    month: "Month",
    day: "Day",
    today: "Today",
    week: "Week",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    okText: "OK",
    nowText: "Now",
    timeText: "Time",
  },
  modal: {
    okText: "OK",
    cancelText: "Cancel",
  },
  drawer: {
    okText: "OK",
    cancelText: "Cancel",
  },
} as Locale
