import { createIcon } from "../create-icon"

export const DeleteIcon = createIcon({
  title: "DeleteIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 4.00024C6.5 3.44796 6.94772 3.00024 7.5 3.00024H8.5C9.05228 3.00024 9.5 3.44796 9.5 4.00024H12C12.5523 4.00024 13 4.44796 13 5.00024C13 5.55253 12.5523 6.00024 12 6.00024V12.0002C12 12.5525 11.5523 13.0002 11 13.0002H5C4.44772 13.0002 4 12.5525 4 12.0002L4 6.00024C3.44772 6.00024 3 5.55253 3 5.00024C3 4.44796 3.44772 4.00024 4 4.00024H6.5ZM5.99998 7.50041C5.99998 7.22426 6.22384 7.00041 6.49998 7.00041C6.77612 7.00041 6.99998 7.22426 6.99998 7.50041V10.5004C6.99998 10.7765 6.77612 11.0004 6.49998 11.0004C6.22384 11.0004 5.99998 10.7765 5.99998 10.5004V7.50041ZM8.99998 7.50041C8.99998 7.22426 9.22384 7.00041 9.49998 7.00041C9.77612 7.00041 9.99998 7.22426 9.99998 7.50041V10.5004C9.99998 10.7765 9.77612 11.0004 9.49998 11.0004C9.22384 11.0004 8.99998 10.7765 8.99998 10.5004V7.50041Z"
      fill="currentColor"
    />
  ),
})

DeleteIcon.displayName = "DeleteIcon"
