import { createIcon } from "../create-icon"

export const EyeOffIcon = createIcon({
  title: "EyeOffIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.85355 3.14645C3.65829 2.95118 3.34171 2.95118 3.14645 3.14645C2.95118 3.34171 2.95118 3.65829 3.14645 3.85355L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L3.85355 3.14645ZM1.35322 7.28528C1.71637 6.63231 2.36968 5.63962 3.35983 4.77405L4.06862 5.48284C3.17802 6.24463 2.57696 7.14235 2.22716 7.77132C2.14564 7.9179 2.14563 8.08209 2.22716 8.22868C2.95357 9.53483 4.76357 12 7.99995 12C8.81361 12 9.53712 11.8442 10.1759 11.5901L10.9372 12.3515C10.1032 12.7456 9.12789 13 7.99995 13C4.18983 13 2.12175 10.0966 1.35322 8.71472C1.10361 8.26591 1.10361 7.7341 1.35322 7.28528ZM5 8C5 7.54829 5.10187 7.10709 5.29289 6.70711L6.06814 7.48236C6.02328 7.64976 6 7.82368 6 8C6 8.53043 6.21071 9.03914 6.58579 9.41421C6.96086 9.78929 7.46957 10 8 10C8.17632 10 8.35024 9.97672 8.51764 9.93186L9.29289 10.7071C8.89291 10.8981 8.45171 11 8 11C7.20435 11 6.44129 10.6839 5.87868 10.1213C5.31607 9.55871 5 8.79565 5 8ZM6.70711 5.29289L7.48236 6.06814C7.64976 6.02328 7.82368 6 8 6C8.53043 6 9.03914 6.21071 9.41421 6.58579C9.78929 6.96086 10 7.46957 10 8C10 8.17632 9.97672 8.35024 9.93186 8.51764L10.7071 9.29289C10.8981 8.89291 11 8.45171 11 8C11 7.20435 10.6839 6.44129 10.1213 5.87868C9.55871 5.31607 8.79565 5 8 5C7.54829 5 7.10709 5.10187 6.70711 5.29289ZM13.7727 8.22868C13.4229 8.85763 12.8219 9.75533 11.9313 10.5171L12.6401 11.2259C13.6302 10.3603 14.2835 9.36768 14.6467 8.71472C14.8963 8.2659 14.8963 7.73409 14.6467 7.28528C13.8781 5.9034 11.8101 3 7.99995 3C6.87204 3 5.89679 3.25444 5.06273 3.64851L5.82405 4.40984C6.46283 4.15581 7.18631 4 7.99995 4C11.2363 4 13.0463 6.46517 13.7727 7.77132C13.8543 7.9179 13.8543 8.08209 13.7727 8.22868Z"
      fill="currentColor"
    />
  ),
})
