import { createIcon } from "../create-icon"

export const CameraIcon = createIcon({
  title: "CameraIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17095 4.27639C6.25564 4.107 6.42877 4 6.61816 4H9.38209C9.57148 4 9.74461 4.107 9.8293 4.27639L10.2765 5.17082C10.5306 5.679 11.05 6 11.6182 6H12.5C12.7761 6 13 6.22386 13 6.5V11.5C13 11.7761 12.7761 12 12.5 12H3.5C3.22386 12 3 11.7761 3 11.5V6.5C3 6.22386 3.22386 6 3.5 6H4.38209C4.95025 6 5.46964 5.679 5.72373 5.17082L6.17095 4.27639ZM6.61816 3C6.05 3 5.53061 3.321 5.27652 3.82918L4.8293 4.72361C4.74461 4.893 4.57148 5 4.38209 5H3.5C2.67157 5 2 5.67157 2 6.5V11.5C2 12.3284 2.67157 13 3.5 13H12.5C13.3284 13 14 12.3284 14 11.5V6.5C14 5.67157 13.3284 5 12.5 5H11.6182C11.4288 5 11.2556 4.893 11.1709 4.72361L10.7237 3.82918C10.4696 3.321 9.95025 3 9.38209 3H6.61816ZM6.5 8.5C6.5 7.67157 7.17157 7 8 7C8.82843 7 9.5 7.67157 9.5 8.5C9.5 9.32843 8.82843 10 8 10C7.17157 10 6.5 9.32843 6.5 8.5ZM8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6Z"
      fill="currentColor"
    />
  ),
})

CameraIcon.displayName = "CameraIcon"
