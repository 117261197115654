import { createIcon } from "../create-icon"

export const DependencyIcon = createIcon({
  title: "DependencyIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path d="M5 7L9 4.5" stroke="currentColor" />
      <path d="M5 9L9 11" stroke="currentColor" />
      <path
        d="M10.5 6C11.6046 6 12.5 5.10457 12.5 4H11.5C11.5 4.55228 11.0523 5 10.5 5V6ZM8.5 4C8.5 5.10457 9.39543 6 10.5 6V5C9.94772 5 9.5 4.55228 9.5 4H8.5ZM10.5 2C9.39543 2 8.5 2.89543 8.5 4H9.5C9.5 3.44772 9.94772 3 10.5 3V2ZM12.5 4C12.5 2.89543 11.6046 2 10.5 2V3C11.0523 3 11.5 3.44772 11.5 4H12.5ZM10.5 13.5C11.6046 13.5 12.5 12.6046 12.5 11.5H11.5C11.5 12.0523 11.0523 12.5 10.5 12.5V13.5ZM8.5 11.5C8.5 12.6046 9.39543 13.5 10.5 13.5V12.5C9.94772 12.5 9.5 12.0523 9.5 11.5H8.5ZM10.5 9.5C9.39543 9.5 8.5 10.3954 8.5 11.5H9.5C9.5 10.9477 9.94772 10.5 10.5 10.5V9.5ZM12.5 11.5C12.5 10.3954 11.6046 9.5 10.5 9.5V10.5C11.0523 10.5 11.5 10.9477 11.5 11.5H12.5ZM5 8C5 8.55228 4.55228 9 4 9V10C5.10457 10 6 9.10457 6 8H5ZM4 7C4.55228 7 5 7.44772 5 8H6C6 6.89543 5.10457 6 4 6V7ZM3 8C3 7.44772 3.44772 7 4 7V6C2.89543 6 2 6.89543 2 8H3ZM4 9C3.44772 9 3 8.55228 3 8H2C2 9.10457 2.89543 10 4 10V9Z"
        fill="currentColor"
      />
    </>
  ),
})

DependencyIcon.displayName = "DependencyIcon"
