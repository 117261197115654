import { createIcon } from "../create-icon"

export const LoadingIcon = createIcon({
  title: "LoadingIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 2.75C7.25 2.33579 7.58579 2 8 2C9.18669 2 10.3467 2.35189 11.3334 3.01118C12.3201 3.67047 13.0892 4.60754 13.5433 5.7039C13.9974 6.80026 14.1162 8.00666 13.8847 9.17054C13.6532 10.3344 13.0818 11.4035 12.2426 12.2426C11.4035 13.0818 10.3344 13.6532 9.17054 13.8847C8.00666 14.1162 6.80026 13.9974 5.7039 13.5433C4.60754 13.0892 3.67047 12.3201 3.01118 11.3334C2.35189 10.3467 2 9.18669 2 8C2 7.58579 2.33579 7.25 2.75 7.25C3.16421 7.25 3.5 7.58579 3.5 8C3.5 8.89002 3.76392 9.76004 4.25839 10.5001C4.75285 11.2401 5.45566 11.8169 6.27792 12.1575C7.10019 12.4981 8.00499 12.5872 8.87791 12.4135C9.75082 12.2399 10.5526 11.8113 11.182 11.182C11.8113 10.5526 12.2399 9.75082 12.4135 8.87791C12.5872 8.00499 12.4981 7.10019 12.1575 6.27792C11.8169 5.45566 11.2401 4.75285 10.5001 4.25839C9.76005 3.76392 8.89002 3.5 8 3.5C7.58579 3.5 7.25 3.16421 7.25 2.75Z"
      fill="currentColor"
    />
  ),
})

LoadingIcon.displayName = "LoadingIcon"
