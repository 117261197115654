import { createIcon } from "../create-icon"

export const DoubtIcon = createIcon({
  title: "DoubtIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.83496 6.89453H6C6.05371 5.83984 6.75684 5 8.12891 5C9.35938 5 10.1992 5.76172 10.1992 6.76758C10.1992 7.53418 9.80371 8.07617 9.17871 8.45215C8.55859 8.81836 8.38281 9.0918 8.38281 9.60449V9.92188H7.52832V9.47266C7.52344 8.82812 7.83594 8.38867 8.50488 7.98828C9.07129 7.6416 9.29102 7.33398 9.29102 6.81152C9.29102 6.20605 8.82227 5.76172 8.09473 5.76172C7.35742 5.76172 6.88867 6.19629 6.83496 6.89453ZM8.55664 11.4434C8.55664 11.7559 8.31738 12 8 12C7.6875 12 7.44336 11.7559 7.44336 11.4434C7.44336 11.126 7.6875 10.8867 8 10.8867C8.31738 10.8867 8.55664 11.126 8.55664 11.4434Z"
      fill="currentColor"
    />
  ),
})

DoubtIcon.displayName = "DoubtIcon"
