import { createIcon } from "../create-icon"

export const Result404Icon = createIcon({
  title: "Result404Icon",
  viewBox: "0 0 96 96",
  path: (
    <>
      <path
        d="m12.493 60.846 8.763 5.585L44.57 80.548l33.797-25.323L56.8 43.815l-5.026 3.132-9.566-5.279"
        fill="#D4D6DC"
      />
      <path
        d="M42.363 29.453c-3.045 1.757-5.812 3.824-7.191 6L59.73 49.2c.092-.13 2.632-2.939 8.72-6.452 3.045-1.76 6.443-3.044 11.83-5.37L55.745 23.564c-.366.118-7.294 2.374-13.383 5.89z"
        fill="#FDF9F3"
      />
      <path
        d="M40.093 27.811c-2.328 2.635-4.28 5.441-4.897 7.942L59.48 49.191c7.337-7.557 2.74-6.643 7.395-11.91 2.328-2.634 3.121-.886 7.658-4.534l-23.05-11.294c-.308.236-6.734 1.09-11.39 6.358z"
        fill="#fff"
      />
      <path
        d="M59.75 57.477c.093-.13 1.854-2.447 7.946-5.96 3.358-1.906 8.988-5.49 12.591-6.876V37.36c-3.603 1.388-9.257 3.878-12.615 5.782-6.088 3.514-7.1 5.458-7.95 6.047"
        fill="url(.kn7hw5sina)"
      />
      <path
        d="m23.328 39.615-.15.089c-5.439 3.193-7.296 7.385-7.397 7.602l25.274 14.688c5.917-5.342 9.183-9.953 12.608-11.93.36-.21.734-.392 1.12-.547 2.32-.92 4.812-.353 4.944-.322L35.316 35.428c-4.317.918-8.55 2.205-11.988 4.187z"
        fill="#FDF9F3"
      />
      <path
        d="m15.777 47.3.027 8.427L41.08 70.415l-.021-8.423-25.282-14.691z"
        fill="#ECDBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m57.504 48.994.023 8.375h.587l-.024-8.375h-.586zm-.51 8.404-.022-8.375c.18-.017.36-.029.532-.029l.022 8.375a8.22 8.22 0 0 0-.532.03zm-.532-8.32v.003a8.881 8.881 0 0 0-.503.088v.004a9.605 9.605 0 0 0-.55.126v.008c-.204.059-.408.128-.613.204v.003h-.01c-.387.154-.761.337-1.12.546-1.977 1.14-3.9 3.159-6.31 5.688-1.766 1.854-3.795 3.983-6.299 6.242l.023 8.376c2.5-2.258 4.528-4.385 6.294-6.238 2.413-2.531 4.338-4.551 6.316-5.692a8.61 8.61 0 0 1 1.118-.547h.016v-.004c.205-.081.41-.15.614-.204v-.007c.187-.049.373-.094.558-.129v-.003c.166-.034.33-.062.491-.086v-.003a7.5 7.5 0 0 1 .507-.055l-.023-8.376c-.166.013-.336.031-.509.056zm1.653 8.293-.025-8.375c.242.008.46.025.666.045l.022 8.376-.037-.004a5.924 5.924 0 0 0-.626-.042zm.641-8.33.023 8.375c.306.031.554.068.725.099v-.005a.758.758 0 0 1 .09-.019c.011 0 .022.002.164-.018l-.03-8.287c.183.03.089.014-.044-.009l-.205-.034v-.001c-.168-.031-.41-.07-.723-.101z"
        fill="#C0AE92"
      />
      <path
        d="M59.719 49.18 35.927 40.42c-4.405-2.435-12.078 1.684-16.16 3.713L41.81 57.26c1.595-2.307 3.346-5.78 6.784-7.253 3.56-1.526 9.066-1.241 11.125-.827z"
        fill="#F8EDDA"
      />
      <path
        d="m59.727 49.188-24.17-13.53c-4.406-2.435-13.671 1.014-17.753 3.043l24.819 15.145c1.358-1.964 3.986-3.671 6.607-4.494 1.97-.618 4.14-.717 6.1-.662 1.669 0 3.276.228 4.397.498z"
        fill="#fff"
      />
      <path
        d="m35.55 34.64-.362.813 24.606 13.684.497-.455L35.55 34.64z"
        fill="#FEF9EF"
      />
      <path
        d="M63.902 43.262c-.143.483-2.4 3.897-3.644 5.449l4.758-3.974c-.296-.677-.97-1.963-1.114-1.475z"
        fill="#F8EDDA"
      />
      <path
        d="M47.607 30.532c2.804-1.798 7.463-2.012 10.404-.481 2.94 1.531 3.051 4.227.242 6.034-2.809 1.807-7.463 2.014-10.404.482-2.94-1.531-3.048-4.237-.242-6.035zm6.246 1.6a1.228 1.228 0 0 0-.661-.69 3.01 3.01 0 0 0-1.604-.33 3.391 3.391 0 0 0-1.677.544c-.566.361-.823.744-.75 1.148.071.404.37.709.937 1.015l.485-.312c-.432-.236-.692-.482-.761-.742-.086-.299.096-.58.522-.855.36-.237.776-.373 1.206-.395.404-.016.806.073 1.165.258a.889.889 0 0 1 .468.536c.026.208.035.417.029.626-.029.24.015.482.127.696.136.232.341.417.586.53l.246.126.492-.316-.243-.125a1.046 1.046 0 0 1-.481-.453.857.857 0 0 1-.07-.515c.012-.25.006-.502-.019-.751l.003.005zm2.297 2.543a.313.313 0 0 0-.192-.23.86.86 0 0 0-.835.043.3.3 0 0 0-.174.249c-.002.09.057.17.186.236.129.063.273.09.416.077.15 0 .295-.04.424-.115a.344.344 0 0 0 .18-.253"
        fill="#FF5E2F"
      />
      <defs>
        <linearGradient
          className="kn7hw5sina"
          x1="63.227"
          y1="52.172"
          x2="89.125"
          y2="37.877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0AE92" />
          <stop offset="1" stopColor="#ECDBBB" />
        </linearGradient>
      </defs>
    </>
  ),
})

Result404Icon.displayName = "Result404Icon"
