import { createIcon } from "../create-icon"

export const LinkIcon = createIcon({
  title: "LinkIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6887 3.60576C11.53 3.54041 11.36 3.50712 11.1884 3.50782C11.0168 3.50852 10.847 3.54319 10.6889 3.60983C10.5307 3.67647 10.3873 3.77376 10.267 3.89609L10.2641 3.89899L7.71862 6.44447C7.47484 6.68826 7.33789 7.01891 7.33789 7.36368C7.33789 7.70844 7.47484 8.03909 7.71862 8.28289L7.01149 8.98997C6.58019 8.55864 6.33789 7.97365 6.33789 7.36368C6.33789 6.75371 6.58019 6.16872 7.01149 5.73739L9.55564 3.19324C9.76826 2.97753 10.0214 2.80593 10.3005 2.68831C10.5803 2.5704 10.8807 2.50907 11.1843 2.50783C11.4879 2.5066 11.7888 2.56548 12.0695 2.6811C12.3503 2.79672 12.6053 2.96678 12.82 3.18147C13.0347 3.39616 13.2048 3.65123 13.3204 3.93197C13.436 4.21271 13.4949 4.51356 13.4937 4.81718C13.4924 5.12079 13.4311 5.42116 13.3132 5.70095C13.1956 5.97996 13.0241 6.233 12.8085 6.44556L11.2054 8.05319L10.4973 7.34707L12.1054 5.73449C12.2277 5.61414 12.325 5.47077 12.3917 5.31263C12.4583 5.15448 12.493 4.98471 12.4937 4.81311C12.4944 4.6415 12.4611 4.47145 12.3957 4.31277C12.3304 4.15409 12.2343 4.00992 12.1129 3.88857C11.9916 3.76723 11.8474 3.67111 11.6887 3.60576ZM8.98959 7.01023C9.42089 7.44156 9.66319 8.02655 9.66319 8.63652C9.66319 9.24649 9.42089 9.83148 8.98959 10.2628L6.4441 12.8083C6.44409 12.8083 6.44411 12.8083 6.4441 12.8083C6.01278 13.2397 5.42776 13.482 4.81776 13.4821C4.20775 13.4821 3.62271 13.2398 3.19134 12.8085C2.75997 12.3772 2.51761 11.7922 2.51758 11.1822C2.51754 10.5721 2.75983 9.98711 3.19115 9.55574L4.67617 8.07072L5.38328 8.77782L3.8983 10.2628C3.65451 10.5066 3.51756 10.8373 3.51758 11.1821C3.5176 11.5269 3.65459 11.8576 3.89841 12.1013C4.14223 12.3451 4.47291 12.4821 4.8177 12.4821C5.16249 12.482 5.49316 12.3451 5.73695 12.1012L8.28246 9.55573C8.52624 9.31193 8.66319 8.98129 8.66319 8.63652C8.66319 8.29175 8.52624 7.96111 8.28246 7.71731L8.98959 7.01023Z"
      fill="currentColor"
    />
  ),
})

LinkIcon.displayName = "LinkIcon"
